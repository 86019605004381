import React from "react";
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
} from "recharts";

function addBars(barsData) {
    return barsData.map((element) => (
        <Bar
            key={element.id}
            dataKey={element.key}
            fill={element.fill}
        />
    ))
}

export default function ReviewBarChart(props) {
    return (
        <ResponsiveContainer>
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.dataKey}/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                {addBars(props.bars)}
            </BarChart>
        </ResponsiveContainer>
    );
}