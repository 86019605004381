import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import App from './pages/App';
import Login from './components/login/Login';
import Team from './pages/team'
import Reviews from './components/displayreviews/Reviews'
import ReviewsFeature from './components/reviewsfeature/ReviewsFeature'
import ReviewsPromotion from './components/reviewspromotion/ReviewsPromotion'
import FilterReviews from './components/filterreviews/FilterReviews'
import Insights from './components/productinsight/Insights'
import Compare from "./components/compare/Compare";
import Distributors from "./components/distributor/Distributors";
import ContactUs from "./pages/ContactUs";
import PrivateRoute from './components/route/PrivateRoute';
import PublicRoute from './components/route/PublicRoute';

const routing = (
    <Router>
        <Switch>
            <PublicRoute exact path="/" component={App} restricted={false}/>
            <PublicRoute exact path="/login" component={Login} restricted={true}/>
            <PublicRoute exact path="/team" component={Team} restricted={false}/>
            <PublicRoute exact path="/contact" component={ContactUs} restricted={false}/>
            <PrivateRoute exact path="/reviews" component={Reviews} restricted={true}/>
            <PrivateRoute exact path="/features" component={ReviewsFeature} restricted={true}/>
            <PrivateRoute exact path="/promotion" component={ReviewsPromotion} restricted={true}/>
            <PrivateRoute exact path="/filter" component={FilterReviews} restricted={true}/>
            <PrivateRoute exact path="/insights" component={Insights} restricted={true}/>
            <PrivateRoute exact path="/compare" component={Compare} restricted={true}/>
            <PrivateRoute exact path="/distributors" component={Distributors} restricted={true}/>
        </Switch>
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));