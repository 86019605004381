import React from "react";
import {Container} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import Header from "../base/Header";
import DisplayFilteredReviews from "./DisplayFilteredReviews";
import Footer from "../base/Footer";
import isTokenValid from "../../utils/isTokenValid";


function FilterReviews() {

    const history = useHistory();

    if (isTokenValid()) {
        return (
            <React.Fragment>
                <Header/>
                <br/>
                <br/>
                <Container maxWidth="lg">
                    <DisplayFilteredReviews/>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    } else {
        history.push('/')
    }
}

export default FilterReviews;