import React from "react";
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import './App.css';
import Header from "../components/base/Header";
import Footer from "../components/base/Footer";
import {Container, CssBaseline, Grid} from "@material-ui/core";
import mainImg from "./main.png"
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Button} from "@material-ui/core";
import "aos/dist/aos.css";
import {
    FaChartPie,
    FaCloudDownloadAlt,
    FaUsersCog,
} from "react-icons/all";
import {IconContext} from "react-icons";


const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 200,
    },
    img: {
        width: '70%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    h3: {
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
});

class App extends React.Component {

    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <Header/>
                <br/>
                <br/>
                <br/>
                <section id='section1' className='section1'>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Zoom in={true}>
                                    <h1>
                                        <span style={{color: '#2E4A62'}}>Insights</span>
                                        <span style={{color: '#417B85'}}> AI</span>
                                    </h1>
                                </Zoom>
                                <Zoom in={true} timeout={700}>
                                    <h2>Identify customer needs and wants real time</h2>
                                </Zoom>
                                <Zoom in={true} timeout={900}>
                                    <p className='explanation'>
                                        Breakthrough 'Natural Language Processing' platform that analyzes customer
                                        comments to uncover human emotions and motivations that drive purchasing
                                        behaviors towards a brand or product.
                                    </p>
                                </Zoom>
                                <Zoom in={true} timeout={1500}>
                                    <div>
                                        <Button className='btnContactUs' href='/contact'>
                                            Contact Us to Find out More
                                        </Button>
                                    </div>
                                </Zoom>
                            </Grid>
                            <Fade in={true} timeout={1800}>
                                <Grid item xs={4}>
                                    <div>
                                        <img src={mainImg} alt=""/>
                                    </div>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Container>
                </section>
                <section id='section2' className='section2'>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={2500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div>
                                        <h2>
                                            How
                                            <span style={{color: '#2E4A62'}}> Insights</span>
                                            <span style={{color: '#417B85'}}> AI </span>
                                            Works
                                        </h2>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={2800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <FaCloudDownloadAlt/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <p>
                                            <span style={{color: '#2E4A62'}}>Insights </span>
                                            <span style={{color: '#417B85'}}>AI</span> <span style={{fontWeight: 600}}> aggregates all publicly
                                            available information </span> (blogs, news articles, customer reviews, etc.)
                                            related to a brand and its competitive set.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3300}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <FaUsersCog/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <p>
                                            Our algorithm then <span style={{fontWeight: 600}}> discovers the personal
                                            motivations </span> that explain
                                            how customers feel about a brand or product and WHY.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <FaChartPie/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <p>
                                            <span style={{fontWeight: 600}}> Real-time actionable insights </span> are
                                            shared via direct
                                            messages and customized dashboards.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        </Grid>
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={2500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div>
                                        <h2>
                                            How is
                                            <span style={{color: '#2E4A62'}}> Insights</span>
                                            <span style={{color: '#417B85'}}> AI </span>
                                            different?
                                        </h2>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={2800}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <div>
                                                <span style={{fontSize: 28, fontWeight: 600}}>1</span>
                                            </div>
                                        </div>
                                        <p>
                                            <span style={{color: '#2E4A62'}}>Insights </span>
                                            <span style={{color: '#417B85'}}>AI</span> aggregates content from different
                                            sources and multiple languages <span style={{fontWeight: 600}}>to generate holistic insights. </span>
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3300}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <div>
                                                <span style={{fontSize: 28, fontWeight: 600}}>2</span>
                                            </div>
                                        </div>
                                        <p>
                                            It detects <span style={{fontWeight: 600}}> shifting preferences </span>
                                            and measures inertia of customer sentiment over time.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <div>
                                                <span style={{fontSize: 28, fontWeight: 600}}>3</span>
                                            </div>
                                        </div>
                                        <p>
                                            Going beyond keyword analysis, the platform <span style={{fontWeight: 600}}>
                                            identifies the underlying themes </span> behind what customers are
                                            talking about.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <div>
                                                <span style={{fontSize: 28, fontWeight: 600}}>4</span>
                                            </div>
                                        </div>
                                        <p>
                                            <span style={{fontWeight: 600}}> Intrinsic bias is accounted for</span>,
                                            enabling a more accurate prediction of customer behaviors.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        </Grid>
                    </Container>
                </section>
                <br/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(App);
