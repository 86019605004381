import React from "react";
import Header from "../base/Header";
import {Container} from "@material-ui/core";
import InsightReviews from "./InsightReviews";
import Footer from "../base/Footer";


function Insights() {
    return (
        <React.Fragment>
            <Header/>
            <br/>
            <br/>
            <Container maxWidth="lg">
                <InsightReviews/>
            </Container>
            <Footer/>
        </React.Fragment>
    );
}

export default Insights;