import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TableFilteredReviews from "./TableFilteredReviews";

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 200,
    },
});

class DisplayFilteredReviews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: '1',
            source: '0',
            feature: '0',
            month: 'May 2021',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(event) {
        await this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h1>Filter Reviews</h1>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                                <FormControl>
                                    <InputLabel id="select-product">Product</InputLabel>
                                    <Select
                                        labelId="select-product"
                                        id="select-product"
                                        name="product"
                                        value={this.state.product}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={'0'}>All</MenuItem>
                                        <MenuItem value={'1'}>Okka Minio Türk Kahvesi Makinesi</MenuItem>
                                        <MenuItem value={'2'}>A860-07 Kahvekolik</MenuItem>
                                        <MenuItem value={'34'}>Hatır Hüp Türk Kahve Makinesi</MenuItem>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                                <FormControl>
                                    <InputLabel id="select-source">Source</InputLabel>
                                    <Select
                                        labelId="select-source"
                                        id="select-source"
                                        name="source"
                                        value={this.state.source}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={'0'}>All</MenuItem>
                                        <MenuItem value={'1'}>Trendyol</MenuItem>
                                        <MenuItem value={'2'}>Hepsiburada</MenuItem>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper}>
                                <FormControl>
                                    <InputLabel id="select-feature">Feature</InputLabel>
                                    <Select
                                        labelId="select-feature"
                                        id="select-feature"
                                        name="feature"
                                        value={this.state.feature}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={'0'}>All</MenuItem>
                                        <MenuItem value={'3'}>Fiyat</MenuItem>
                                        <MenuItem value={'4'}>Kopuklu</MenuItem>
                                        <MenuItem value={'2'}>Miktar</MenuItem>
                                        <MenuItem value={'5'}>Teslimat</MenuItem>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <TableFilteredReviews
                                    key={this.state.product + this.state.source + this.state.feature + this.state.month}
                                    product={this.state.product}
                                    source={this.state.source}
                                    feature={this.state.feature}
                                    month={this.state.month}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>)
    }
}

export default withStyles(useStyles)(DisplayFilteredReviews);