import React from "react";
import {Container} from "@material-ui/core";
import {Zoom} from "@material-ui/core";
import './team.css';
import Header from "../components/base/Header";
import Footer from "../components/base/Footer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import "aos/dist/aos.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import ceoImg from "./bugra1.png"

class Team extends React.Component {

    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <Header/>
                <section id='section10' className='section10'>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Grid item xs={12} className='centerContent'>
                                <br/>
                                <br/>
                                <br/>
                                <Zoom in={true} timeout={500}>
                                    <h2>
                                        Our <span style={{color: '#6993b8'}}>TEAM</span>
                                    </h2>
                                </Zoom>
                                <br/>
                            </Grid>
                            <Zoom in={true} timeout={800}>
                                <Grid item xs={6}>
                                    <div>
                                        <img src={ceoImg} alt=""/>
                                    </div>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={1300}>
                                <Grid item xs={6}>
                                    <Paper className='padding'>
                                        <h1>
                                            Bugra M Gezer
                                        </h1>
                                        <h3>
                                            CEO, Founder, Inventor
                                        </h3>
                                        <p className='justify'>
                                            Bugra M Gezer developed his first neural network while working for the
                                            nuclear
                                            reactor design center of Electricite de France. He then became a successful
                                            private equity investor focusing on the global insurance industry. He
                                            participated in the startup of several insurance companies and served on
                                            their
                                            board of directors.</p>
                                        <p className='justify'>
                                            He has developed the core technology of Futurist AI over the past 3 years.</p>
                                        <p className='justify'>
                                            He holds a degree in Applied Mathematics from University Joseph Fourier, a
                                            software engineering degree and an MS in Artificial Intelligence from INSA
                                            Lyon
                                            and an MBA in finance from Columbia Business School. He is fluent in
                                            multiple languages including English, French and Turkish.
                                        </p>
                                        <p className='bold'>bugra@futuristai.com</p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        </Grid>
                    </Container>
                </section>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Team;