import React from 'react';
import './SummaryStatistics.css'
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import axios from "axios";

const date = {
    'May 2021': '?date=2021-05-01',
    'April 2021': '?date=2021-04-01',
    'March 2021': '?date=2021-03-01',
    'February 2021': '?date=2021-02-01',
    'January 2021': '?date=2021-01-01'
}

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

function Intent(intent) {
    if (intent === 1) {
        return `Anneye Hediye`
    } else {
        return `Çeyiz`
    }
}

class IntentSummaryStatistics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            intent: 1,
            product: 1,
            month: 'May 2021',
            isLoading: true,
            statistics: [],
        }
    }

    async componentDidMount() {
        console.log('This is the IntentStatistics intent props: ', this.props.intent);
        console.log('This is the IntentStatistics product props: ', this.props.product);
        console.log('This is the IntentStatistics month props: ', this.props.month);
        console.log('This is the IntentStatistics state : ', this.state);
        await this.setState({
            intent: this.props.intent,
            product: this.props.product,
            month: this.props.month
        });

        console.log('IntentStatistics state: ', this.state)

        const headers = {
            Authorization: localStorage.getItem("access_token")
                ? 'Bearer ' + localStorage.getItem("access_token")
                : null,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
        console.log('IntentStatistics Headers: ', headers);
        let url = 'https://reviews-rest-api.herokuapp.com/api/intent/statistics'
            + date[this.state.month]
            + '&product='
            + this.state.product
            + '&intent='
            + this.state.intent;
        console.log('This is the IntentStatistics url: ', url);

        await axios.get(url, {headers: headers})
            .then((response) => {
                console.log('IntentStatistics response status: ', response.status);
                console.log('IntentStatistics response data: ', response.data);
                this.setState({
                    isLoading: false,
                    statistics: response.data
                });
            })
            .catch((error) => {
                console.log('SummaryStatistics Axios error: ', error);
                this.setState({
                    isLoading: false,
                    statistics: []
                });
            });

        console.log('IntentStatistics State', this.state);
    }

    render() {
        const {classes} = this.props;
        const count = this.state.statistics.reduce((count, intentProduct) => count + intentProduct.count, 0);
        const total = this.state.statistics.reduce((count, intentProduct) => count + intentProduct.total, 0);
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <h3>{Intent(this.props.intent)}</h3>
                        <h3>{(count/total*100).toFixed(2)}% </h3>
                    </Paper>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(IntentSummaryStatistics);