import React from 'react';
import {Redirect} from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import axios from "axios";
import isTokenValid from "../../utils/isTokenValid";

function Source(id){
    if (id === 1) {
        return 'Trendyol';
    } else {
        return 'Hepsiburada';
    }
}

function Date(date) {
    const splitDate = date.split('-');
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
}

class TableFilteredReviews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            product: '1',
            source: '0',
            feature: '0',
            month: 'May 2021',
            reviews: []
        };
    }

    async componentDidMount() {
        console.log('This is the TableFilteredReviews product props: ', this.props.product);
        console.log('This is the TableFilteredReviews source props: ', this.props.source);
        console.log('This is the TableFilteredReviews feature props: ', this.props.feature);
        console.log('This is the TableFilteredReviews month props: ', this.props.month);
        console.log('This is the TableFilteredReviews state : ', this.state);
        await this.setState({
            product: this.props.product,
            source: this.props.source,
            feature: this.props.feature,
            month: this.props.month
        });
        console.log('This is the state product props after setState: ', this.state);
        const headers = {
            Authorization: localStorage.getItem("access_token")
                ? 'Bearer ' + localStorage.getItem("access_token")
                : null,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
        console.log('Basic Table Headers: ', headers);

        let url = 'https://reviews-rest-api.herokuapp.com/api/review/filter/'
            + this.state.product
            + '/'
            + this.state.source
            + '/'
            + this.state.feature

        console.log('This is the url: ', url);

        await axios.get(url, {headers: headers})
            .then((response) => {
                console.log('Basic Table response status: ', response.status);
                console.log('Basic Table response data: ', response.data.data);
                this.setState({
                    isLoading: false,
                    reviews: response.data.data
                });
            })
            .catch((error) => {
                console.log('Basic Table Axios error: ', error);
                this.setState({
                    isLoading: false,
                    reviews: []
                });
            });
    }

    render() {
         const style = {
             width: 125,
         }
         const headerStyle = {
             fontWeight: 900,
         }
        if (this.state.isLoading) {
            return (<h3>Reviews Loading...</h3>)
        } else {
            if (isTokenValid()) {
                return (
                    <React.Fragment>
                        <Container maxWidth="lg">
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={headerStyle} align='center'>id</TableCell>
                                            <TableCell style={headerStyle} align='center'>Date</TableCell>
                                            <TableCell style={headerStyle} align='center'>Source</TableCell>
                                            <TableCell style={headerStyle} align='center'>Review</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.reviews.map(({date, id, review, source}) => (
                                            <TableRow key={id}>
                                                <TableCell align="center" component="th" scope="row">{id}</TableCell>
                                                <TableCell style={style} align="center">{Date(date)}</TableCell>
                                                <TableCell align="center">{Source(source)}</TableCell>
                                                <TableCell align="left">{review}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </React.Fragment>
                );
            } else {
                return <Redirect to='/'/>
            }
        }

    }
}

export default TableFilteredReviews