import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ArchiveIcon from '@material-ui/icons/Archive';
import CompareCharts from "./CompareCharts";
import {Link} from 'react-router-dom'


const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 200,
    },
});

class CompareReviews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feature: 2,
            month: 'May 2021',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(event) {
        console.log('Product handleChange', event.target.value);
        await this.setState({[event.target.name]: event.target.value});
        console.log('Change state with handleChange: ', event.target.name, event.target.value);
        console.log('State after handleChange: ', this.state);
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h1>Brand Comparison Across All Distributors</h1>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <FormControl>
                                    <InputLabel id="select-month">Month</InputLabel>
                                    <Select
                                        labelId="select-month"
                                        id="select-month"
                                        name="month"
                                        value={this.state.month}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value={'May 2021'}>May 2021</MenuItem>
                                        <MenuItem value={'April 2021'}>April 2021</MenuItem>
                                        <MenuItem value={'March 2021'}>March 2021</MenuItem>
                                        <MenuItem value={'February 2021'}>February 2021</MenuItem>
                                        <MenuItem value={'January 2021'}>January 2021</MenuItem>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                    <CompareCharts
                        key={this.state.month + this.state.feature}
                        month={this.state.month}
                        feature={this.state.feature}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Link style={{ textDecoration: 'none' }} to='/features'>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        endIcon={<ArchiveIcon/>}
                                    >
                                        Okka Minio Overflow Reviews
                                    </Button>
                                </Link>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>)
    }
}

export default withStyles(useStyles)(CompareReviews);