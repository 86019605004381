import React, {useState} from "react";
import {useHistory} from 'react-router-dom'
import {Zoom} from "@material-ui/core";
import Footer from "../components/base/Footer";
import Header from "../components/base/Header";
import {Container} from "@material-ui/core";
import {Button} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import {TextField} from "@material-ui/core";
import {FormControlLabel} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function ContactUs() {
    const classes = useStyles();
    const history = useHistory();

    const initialFormData = Object.freeze({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            // Trimming any whitespace
            [e.target.name]: e.target.value.trim(),
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submit Pressed!')

        const messageContent = formData.firstName
            + ' ' + formData.lastName
            + ' - ' + formData.email
            + ' : MESSAGE : ' + formData.message;

        const data = {
            service_id: 'service_qfa4opf',
            template_id: 'template_2attkvk',
            user_id: 'user_sYSeuNKOEJprNcYsoTJV6',
            template_params: {
                'from_name': 'Info',
                'to_name': 'Bugra',
                'message': messageContent,
            }
        };
        axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
            .then((response) => {
                console.log('response status: ', response.status);
                history.push('/');
            })
            .catch((error) => {
                console.log('Axios error: ', error);
            });
    }

    return (
        <React.Fragment>
            <Header/>
            <Container maxWidth="sm">
                <Zoom in={true} timeout={1300}>
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h4">
                            How can we help?
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="message"
                                        label="How can we help?"
                                        id="message"
                                        multiline
                                        rowsMax={5}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary"/>}
                                        label="I want to receive inspiration, marketing promotions and updates
                                    via email."
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                Contact us
                            </Button>
                        </form>
                    </div>
                </Zoom>
            </Container>
            <Footer/>
        </React.Fragment>
    );
}

export default ContactUs;