import React from "react";
import {
    ResponsiveContainer,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Area
} from "recharts";

function addLines(areaData) {
    return areaData.map((element) => (
        <Area
            key={element.id}
            type={element.type}
            dataKey={element.key}
            stroke={element.stroke}
            fillOpacity={1}
            fill={`url(#color${element.id})`}
        />
    ))
}

function addGradient(areaData) {
    return areaData.map((element) => (
        <linearGradient
            key={element.id}
            id={`color${element.id}`}
            x1='0'
            y1='0'
            x2='0'
            y2='1'
        >
            <stop offset="5%" stopColor={element.stroke} stopOpacity={0.8}/>
            <stop offset="70%" stopColor={element.stroke} stopOpacity={0}/>
        </linearGradient>
    ))
}

export default function ReviewAreaChart(props) {
    return (
        <ResponsiveContainer>
            <AreaChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <defs>
                    {addGradient(props.lines)}
                </defs>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.dataKey}/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                {addLines(props.lines)}
            </AreaChart>
        </ResponsiveContainer>
    );
}