import React, {useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from "axios";
import LoginHeader from './LoginHeader';
import Footer from "../base/Footer";
import jwt_decode from "jwt-decode";
import isTokenValid from "../../utils/isTokenValid";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login() {
    const classes = useStyles();
    const history = useHistory();
    const initialFormData = Object.freeze({
        username: '',
        password: '',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            // Trimming any whitespace
            [e.target.name]: e.target.value.trim(),
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const credentials = {username: formData.username, password: formData.password}
        console.log('Credentials: ', credentials)
        axios.post('https://reviews-rest-api.herokuapp.com/api/token/', credentials)
            .then((response) => {
                console.log('response status: ', response.status);
                console.log('response data: ', response.data);
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);

                let decodedAccessToken = jwt_decode( localStorage.getItem('access_token'))
                localStorage.setItem('access_token_exp', decodedAccessToken.exp);
                console.log('Access token exp: ', localStorage.getItem('access_token_exp'));
                let decodedRefreshToken = jwt_decode( localStorage.getItem('refresh_token'))
                localStorage.setItem('refresh_token_exp', decodedRefreshToken.exp);
                console.log('Refresh token exp: ', decodedRefreshToken.exp);
                history.push('/');

            })
            .catch((error) => {
                console.log('Axios error: ', error);
            });

    }
    if (isTokenValid()) {
        return (
            <Redirect to={'/'}/>
        );
    } else {
        return (
            <React.Fragment>
                <LoginHeader/>
                <Container maxWidth="xs">
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="default"
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                Sign In
                            </Button>
                        </form>
                    </div>
                </Container>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default Login;