import React from "react";
import axios from "axios";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ReviewAreaChart from "../charts/ReviewAreaChart";
import ReviewRadarChart from "../charts/ReviewRadarChart";
import {Redirect} from "react-router-dom";
import isTokenValid from "../../utils/isTokenValid";

const monthConvert = {
    'May 2021': [5, 1, 2021],
    'April 2021': [4, 1, 2021],
    'March 2021': [3, 1, 2021],
    'February 2021': [2, 1, 2021],
    'January 2021': [1, 1, 2021],
}

const linesData = [
    {id: 1, type: 'monotone', key: 'A860-07 Kahvekolik', stroke: '#27C3F3', active: true},
    {id: 2, type: 'monotone', key: 'OK004 Okka Minio', stroke: '#057091', active: false},
    {id: 3, type: 'monotone', key: 'Hatır Hüp', stroke: '#033453', active: false},
]

const radarsData = [
    {id: 1, name: 'A860-07 Kahvekolik', key: 'A', stroke: '#27C3F3', fill: '#27C3F3', opacity: '0.3'},
    {id: 2, name: 'Hatır Hüp', key: 'B', stroke: '#057091', fill: '#057091', opacity: '0.3'},
    {id: 3, name: 'OK004 Okka Minio', key: 'C', stroke: '#033453', fill: '#033453', opacity: '0.3'},
]

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class CompareCharts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            product: [],
            feature: [],
            radar: []
        }

    }

    async componentDidMount() {
        console.log('This is the CompareCharts month props: ', this.props.month);
        console.log('This is the CompareCharts feature props: ', this.props.feature);
        const date = monthConvert[this.props.month]

        const headers = {
            Authorization: localStorage.getItem("access_token")
                ? 'Bearer ' + localStorage.getItem("access_token")
                : null,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
        console.log('CompareCharts Headers: ', headers);

        let url = `https://reviews-rest-api.herokuapp.com/api/product/compare/data/${date[2]}/${date[0]}/${date[1]}/5/${this.props.feature}`
        console.log('This is the CompareCharts url: ', url);

        await axios.get(url, {headers: headers})
            .then((response) => {
                console.log('CompareCharts response status: ', response.status);
                console.log('CompareCharts response data: ', response.data);
                this.setState({
                    isLoading: false,
                    product: response.data.product,
                    feature: response.data.feature,
                    radar: response.data.radar
                });
            })
            .catch((error) => {
                console.log('CompareCharts Axios error: ', error);
                this.setState({
                    isLoading: false,
                    product: [],
                    feature: [],
                    radar: [],
                });
            });
    }

    render() {
        const {classes} = this.props;
        if (this.state.isLoading) {
            return (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h3>Data Loading...</h3>
                            </Paper>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        } else {
            if (isTokenValid()) {
                return (
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <div style={{width: '100%', height: 400}}>
                                        <h2>Brand Perception Comparison</h2>
                                        <ReviewAreaChart
                                            key={this.props.month + this.props.feature}
                                            dataKey='name'
                                            data={this.state.product}
                                            lines={linesData}
                                        />
                                    </div>
                                    <br/>
                                    <br/>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <div style={{width: '100%', height: 400}}>
                                        <h2>Brand Perception Comparison by Feature</h2>
                                        <ReviewRadarChart
                                            key={this.props.month + this.props.feature}
                                            data={this.state.radar}
                                            radars={radarsData}
                                        />
                                    </div>
                                    <br/>
                                    <br/>
                                </Paper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            } else {
                return <Redirect to='/'/>
            }
        }
    }
}

export default withStyles(useStyles)(CompareCharts);