import React from "react";
import './Footer.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {Slide, Typography} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Link from '@material-ui/core/Link';


let useStyles = makeStyles((theme) =>
    (
        {
            footer: {
                background: '#000000',
                color: '#ffffff',
                borderTop: `1px solid ${theme.palette.divider}`,
                marginTop: theme.spacing(8),
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
                [theme.breakpoints.up('sm')]: {
                    paddingTop: theme.spacing(6),
                    paddingBottom: theme.spacing(6),
                },
            },
            paper: {
                background: '#000000',
                color: '#ffffff',
            }
        }
    )
);

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'© Futurist AI LLC'}
            {' '}
            {new Date().getFullYear()}
            {' '} - All Rights Reserved
        </Typography>
    );
}

function Footer() {
    const classes = useStyles();
    return (
        <section id='footer' className={classes.footer}>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Slide in={true} timeout={700} direction={'up'}>
                        <Grid item xs={3}>
                            <Link href='https://www.linkedin.com/company/futurist-ai/'>
                                <LinkedInIcon className='icon' fontSize='large'/>
                            </Link>
                        </Grid>
                    </Slide>
                    <Slide>
                        <Grid item xs={3}>
                            <Paper>
                            </Paper>
                        </Grid>
                    </Slide>
                    <Slide>
                        <Grid item xs={3}>
                            <Paper>
                            </Paper>
                        </Grid>
                    </Slide>
                    <Slide in={true} timeout={700} direction={'up'}>
                        <Grid item xs={3}>
                            <Paper className={classes.paper}>
                                <p className='formatParagraph formatCompanyName'>Futurist AI, Inc</p>
                                <p className='formatParagraph'>99 Wall Street</p>
                                <p className='formatParagraph'>Suite 957</p>
                                <p className='formatParagraph'>New York, NY 10005 </p>
                                <br/>
                                <p className='formatParagraph'>info@futuristai.com</p>
                            </Paper>
                        </Grid>
                    </Slide>
                </Grid>
                <Box mt={5}>

                    <Copyright/>
                </Box>
            </Container>
        </section>
    );
}

export default Footer;