import React from "react";
import {
    Radar,
    RadarChart,
    PolarGrid,
    Legend,
    Tooltip,
    PolarAngleAxis,
    ResponsiveContainer,
} from "recharts";

function addRadars(radarsData) {
    return radarsData.map((element) => (
        <Radar
            key={element.id}
            name={element.name}
            dataKey={element.key}
            stroke={element.stroke}
            strokeWidth={2}
            fill={element.fill}
            fillOpacity={element.opacity}
        />
    ))
}

export default function ReviewRadarChart(props) {
    return (
        <ResponsiveContainer>
            <RadarChart data={props.data} >
                <PolarGrid/>
                <Tooltip />
                <PolarAngleAxis dataKey="subject"/>
                {addRadars(props.radars)}
                <Legend />
            </RadarChart>
        </ResponsiveContainer>
    );
}