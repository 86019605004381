import React from "react";
import {AppBar} from "@material-ui/core";
import {Toolbar} from "@material-ui/core";
import {CssBaseline} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import '../../pages/App.css';
import img from "../base/futuristailogo.webp"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        maxWidth: 160,
    },
}));

function LoginHeader() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                position="sticky"
                color="default">
                <Toolbar>
                    <img src={img} alt="logo" className={classes.logo}/>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default LoginHeader;