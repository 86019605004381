import React from "react";
import axios from "axios";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IntentSummaryStatistics from "../IntentSummaryStatistics";
import ReviewBarChart from "../charts/ReviewBarChart";
import ReviewAreaChart from "../charts/ReviewAreaChart";
import {Redirect} from "react-router-dom";
import isTokenValid from "../../utils/isTokenValid";

const monthConvert = {
    'May 2021': [5, 1, 2021],
    'April 2021': [4, 1, 2021],
    'March 2021': [3, 1, 2021],
    'February 2021': [2, 1, 2021],
    'January 2021': [1, 1, 2021],
}

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const barData = [
    {id: 1, key: 'number of reviews', fill: '#86b0cc'}
]

const featureBarData = [
    {id: 1, key: 'Teslimat', fill: '#004c6d'},
    {id: 2, key: 'Tasarım', fill: '#2d6484'},
    {id: 3, key: 'Paketleme', fill: '#4c7c9b'},
    {id: 4, key: 'Miktar', fill: '#6996b3'},
    {id: 5, key: 'Kopuklu', fill: '#86b0cc'},
    {id: 6, key: 'Kalite', fill: '#a3cbe5'},
    {id: 7, key: 'Fiyat', fill: '#c1e7ff'},
]

const linesData = [
    {id: 1, type: 'monotone', key: 'perception score', stroke: '#86b0cc', active: true},
]


class InsightCharts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            bar: [],
            line: [],
            feature: []
        }

    }

    async componentDidMount() {
        console.log('This is the SummaryBarChart month props: ', this.props.month);
        console.log('This is the SummaryBarChart product props: ', this.props.product);
        const date = monthConvert[this.props.month]

        const headers = {
            Authorization: localStorage.getItem("access_token")
                ? 'Bearer ' + localStorage.getItem("access_token")
                : null,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
        console.log('SummaryBarChart Headers: ', headers);

        let url = `https://reviews-rest-api.herokuapp.com/api/product/statistics/data/${date[2]}/${date[0]}/${date[1]}/5/${this.props.product}`
        console.log('This is the SummaryBarChart url: ', url);

        await axios.get(url, {headers: headers})
            .then((response) => {
                console.log('SummaryBarChart response status: ', response.status);
                console.log('SummaryBarChart response data: ', response.data);
                this.setState({
                    isLoading: false,
                    bar: response.data.bar,
                    line: response.data.line,
                    feature: response.data.feature
                });
            })
            .catch((error) => {
                console.log('SummaryBarChart Axios error: ', error);
                this.setState({
                    isLoading: false,
                    line: [],
                    bar: [],
                    feature: [],
                });
            });
    }

    render() {
        const {classes} = this.props;
        if (this.state.isLoading) {
            return (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h3>Data Loading...</h3>
                            </Paper>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        } else {
            if (isTokenValid()) {
                return (
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <h2>Customer Product Perception</h2>
                                    <div style={{width: '100%', height: 400}}>
                                        <ReviewAreaChart
                                            key={this.props.month}
                                            dataKey='name'
                                            data={this.state.line}
                                            lines={linesData}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <h2>Total Number of Customer Reviews</h2>
                                    <div style={{width: '100%', height: 400}}>
                                        <ReviewBarChart
                                            dataKey='name'
                                            key={this.props.month}
                                            data={this.state.bar}
                                            bars={barData}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <h2>Buying Intent</h2>
                                    <div style={{width: '100%'}}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <div style={{width: '100%'}}>
                                                    <IntentSummaryStatistics
                                                        key={this.props.product + this.props.month}
                                                        intent={1}
                                                        product={this.props.product}
                                                        month={this.props.month}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{width: '100%'}}>
                                                    <IntentSummaryStatistics
                                                        key={this.props.product + this.props.month}
                                                        intent={2}
                                                        product={this.props.product}
                                                        month={this.props.month}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <h2>Product Perception by Feature</h2>
                                    <div style={{width: '100%', height: 400}}>
                                        <ReviewBarChart
                                            key={this.props.month}
                                            dataKey='name'
                                            data={this.state.feature}
                                            bars={featureBarData}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
            } else {
                return <Redirect to='/'/>
            }
        }
    }
}

export default withStyles(useStyles)(InsightCharts);